import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-form-validator-core";
import WithoutMsgValidation from "../FormLayouts/WithoutMsgValidation";
import { CONSTANTS } from "../../../helpers/constants";

import PageContainer from "../../UIElements/Containers";
import StageSelect from "../Wizard/StageSelect";

import { getMatrizForm } from "../../../lib/MatrizMeddi";
import "./style.css";
import PaginatedTabs from "./PaginateTabs";
import Question from "./Question";

const content = ({ path }) => {
  const [tabActive, setTabActive] = useState("");
  const [tabIdx, setTabIdx] = useState(1);
  const [formData, setFormData] = useState({});
  const [dynamicForm, setDynamicForm] = useState(null);
  const [lastAnswer, setLastAnswer] = useState();
  const urlParams = new URLSearchParams(window.location.search);

  let handleFormSubmit = async () => {
    const saved = [];
    let counter = 0;
    Object.entries(formData).map(async ([_key, value]) => {
      const req = await fetch(`${CONSTANTS.base_url}api/v1/data/answer`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": CONSTANTS.TOKEN,
        },
        body: JSON.stringify(value),
      });
      const res = await req.json();

      if (res.success) {
        counter++;
        if (counter == Object.entries(formData).length) {
          location.assign(
            `/participants_form?participant_id=${urlParams.get(
              "participante_id"
            )}`
          );
        }
      }
    });

    // if (saved.includes(false)) {
    // } else {
    //
    // }
  };

  useEffect(() => {
    const questionsSaved = localStorage.getItem("questionsSaved");
    if (!questionsSaved) localStorage.setItem("questionsSaved", "");
    (async () => {
      const groups = await getMatrizForm();
      setDynamicForm(groups);
      setTabActive(`${groups[Object.keys(groups)[0]][0].question_group}`);
    })();
  }, []);

  const setAnswer = (questionId, name, id, answer) => {
    const checkboxes = document.querySelectorAll(
      `input[name="${name}${questionId}"]`
    );
    if (checkboxes.length >= 1) {
      checkboxes.forEach((checkbox) => {
        if (checkbox.id === id) {
          if (!checkbox.checked) checkbox.checked = true;
          setFormData({
            ...formData,
            [`question_id_${questionId}`]: {
              participante_id: urlParams.get("participante_id"),
              question_id: questionId,
              answer_value: answer,
              answer_date: new Date().toISOString().slice(0, 10),
            },
          });
        } else {
          checkbox.checked = false;
        }
      });
    } else {
      setFormData({
        ...formData,
        [`question_id_${questionId}`]: {
          participante_id: urlParams.get("participante_id"),
          question_id: questionId,
          answer_value: answer,
          answer_date: new Date().toISOString().slice(0, 10),
        },
      });
    }
setLastAnswer(answer)
    console.log(formData);
  };

  const goNext = () => {
    setTabIdx(tabIdx + 1);
    setTabActive(Object.values(dynamicForm)[tabIdx][0].question_group);
  };

  // const validateRequired = () => {
  //   const missingRequired = [];
  //   const inputsRequired = document.querySelectorAll(`input[require="true"]`);
  //   inputsRequired.forEach((input) => {
  //     const errorMessage = document.getElementById(`error_${input.id}`)
  //     if (!input.value) {
  //       errorMessage.style.display = 'block'
  //       missingRequired.push(input)
  //     } else {
  //       errorMessage.style.display = 'none'
  //     }
  //   });
  //   if(missingRequired.length <= 0){
  //     setTabIdx(tabIdx + 1);
  //     setTabActive(Object.values(dynamicForm)[tabIdx][0].question_group);
  //   }
  // };

  const targetSelector = (cuestion) => {
    switch (cuestion)
    {
      case 1:
        return ( <a
          aria-label=""
          className="btn btn-white btn-cons m-t-10 col-4"
          href={`${CONSTANTS.base_url}api/v1/data/docs/generate/pdf/target/1`}
          download
          style={{ margin: "0 -4rem" }}
        >
         Descargar Tarjeta 1
        </a>)
        break;
      
        case 16:
          return ( <a
            aria-label=""
            className="btn btn-white btn-cons m-t-10 col-4"
            href={`${CONSTANTS.base_url}api/v1/data/docs/generate/pdf/target/1`}
            download
            style={{ margin: "0 -4rem" }}
          >
           Descargar Tarjeta 1
          </a>)
          break;

          case 7:
            return ( <a
              aria-label=""
              className="btn btn-white btn-cons m-t-10 col-4"
              href={`${CONSTANTS.base_url}api/v1/data/docs/generate/pdf/target/2`}
              download
              style={{ margin: "0 -4rem" }}
            >
             Descargar Targeta 2
            </a>)
            break;

          case 8:
            return ( <a
              aria-label=""
              className="btn btn-white btn-cons m-t-10 col-4"
              href={`${CONSTANTS.base_url}api/v1/data/docs/generate/pdf/target/3`}
              download
              style={{ margin: "0 -4rem" }}
            >
             Descargar Targeta 3
            </a>)
            break;

            case 30:
              return ( <a
                aria-label=""
                className="btn btn-white btn-cons m-t-10 col-4"
                href={`${CONSTANTS.base_url}api/v1/data/docs/generate/pdf/target/4`}
                download
                style={{ margin: "0 -4rem" }}
              >
               Descargar Targeta 4
              </a>)
              break;

      default:
        return 
        break;
    }
  }

  return (
    dynamicForm && (
      <div className="page-content-wrapper">
        {/* START PAGE CONTENT */}
        <div
          className="content"
          style={{
            paddingTop: "60px",
          }}
        >
          <PageContainer>
            <div id="rootwizard" className="d-flex m-t-50">
              <div className="nav-tab-dropdown cs-wrapper full-width d-lg-none d-xl-none d-md-none">
                <StageSelect onSelectTabs={setTabActive} />
              </div>
              <ul
                className="col-4 d-none d-md-block d-lg-block d-xl-block p-2 pagination-container justify-content-center p-2 m-b-4"
                role="tablist"
                data-init-reponsive-tabs="dropdownfx"
              >
                {dynamicForm && (
                  <PaginatedTabs
                    dynamicForm={dynamicForm}
                    tabIdx={tabIdx}
                    setTabActive={setTabActive}
                    setTabIdx={setTabIdx}
                    // validations={validateRequired}
                  />
                )}
              </ul>
              <div className="col-8 p-2 tab-content right-container m-b-4">
                <ValidatorForm
                  instantValidate={true}
                  onSubmit={handleFormSubmit}
                  className="p-t-15"
                >
                  <div
                    className={`tab-pane padding-20 sm-no-padding slide-left questions-container ${
                      tabActive ? "active" : ""
                    }`}
                    id={`tab${tabActive}`}
                  >
                    <div className="row row-same-height">
                      <div className="col-md-12">
                        {targetSelector(tabIdx)}
                        { tabActive === "AUTOCONCEPTO - AUTOVALORACIÓN" ?
                          <Question
                            question={
                              dynamicForm["AUTOCONCEPTO - AUTOVALORACIÓN"][10]
                            }
                            options={[
                              "Entrevista de entrada",
                              "Entrevista de seguimiento",
                              "Entrevista de salida",
                            ]}
                            setAnswer={setAnswer}
                            tabActive={tabActive}
                            required={true}
                          /> : ''
                        }
                      </div>

                      <div className="col-md-12">
                        <h4 className="text-start font-weight-bold">
                          {dynamicForm && `${tabIdx}${lastAnswer === 1 && tabIdx === 25 ? 'b' : 
                                    lastAnswer === 0 && tabIdx === 25 ? 'a' : ''}. ${tabActive}`}
                        </h4>
                        {/* <h5 className="text-success text-start font-weight-bold">
                          TARJETA {tabIdx}
                        </h5> */}
                        {dynamicForm &&
                          Object.values(dynamicForm).map((tab, idx) => {
                            return tab.map((key, index) => {
                              let options = [];
                              if (
                                key.question_type === "multiple" ||
                                key.question_type === "varias"
                              )
                                options = JSON.parse(key.question_options).map(
                                  (value, index) => (options[index] = value)
                                );
                              return (
                                <React.Fragment>
                                  <div
                                    style={{
                                      display: `${
                                        key.question_group == tabActive
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                  >
                                    {
                                    lastAnswer === 0 && (key.question_id === 90 || key.question_id === 124 || key.question_id === 125) ? '' :
                                    lastAnswer === 1 && (key.question_id === 125 || key.question_id === 89 || key.question_id === 124) ? '' : 
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: key.question_instruccions
                                          ?.replace("[", "")
                                          .replace("]", "")
                                          .replace(/,/g, "")
                                          .replace(/"/g, ""),
                                      }}
                                    ></div>
                                  }
                                    <div
                                      className={`padding-30 sm-padding-5`}
                                      // className={`padding-30 sm-padding-5 ${
                                      //   key.question_id % 2 == 0
                                      //     ? ""
                                      //     : "customFormInpar"
                                      // }`}
                                    >
                                      {key.question_id === 109 ? ("") :
                                      lastAnswer === 1 && (key.question_id === 125 || key.question_id === 89 || key.question_id === 124) ? '' : 
                                      lastAnswer === 0 && (key.question_id === 90 || key.question_id === 124 || key.question_id === 125) ? '' :
                                      (
                                        <Question
                                          question={key}
                                          options={options}
                                          setAnswer={setAnswer}
                                          tabActive={tabActive}
                                          required={true}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            });
                          })}
                      </div>
                    </div>
                    {tabIdx !== Object.keys(dynamicForm).length && (
                      <button
                        className="btn btn-success btn-cons btn-animated from-left pull-right"
                        type="button"
                        onClick={() => goNext()}
                        // onClick={() => {
                        //   validateRequired(
                        //     tabIdx,
                        //     Object.values(dynamicForm)[tabIdx][0].question_group
                        //   );
                        // }}
                      >
                        <span>Siguiente</span>
                      </button>
                    )}
                    {tabIdx == Object.keys(dynamicForm).length && (
                      <button
                        className="btn btn-success btn-cons btn-animated from-left pull-right"
                        type="submit"
                      >
                        <span>Guardar</span>
                      </button>
                    )}
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
    )
  );
};

export default content;
